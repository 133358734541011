import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GameList from './components/GameList';
import GamePlayer from './components/GamePlayer';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import About from './components/About';
import BloxFruitsArticle from './articles/roblox/BloxFruitsArticle'; 
import MurderMystery2Article from './articles/roblox/MurderMystery2Article'; 
import AdoptMeArticle from './articles/roblox/AdoptMeArticle';
import AnimeFightersSimulatorArticle from './articles/roblox/AnimeFightersSimulatorArticle'; 
import BrookhavenArticle from './articles/roblox/BrookhavenArticle'; 
import PetSimulatorXArticle from './articles/roblox/PetSimulatorXArticle';
import TowerDefenseSimulatorArticle from './articles/roblox/TowerDefenseSimulatorArticle'; 
import AnimeVanguardsArticle from './articles/roblox/AnimeVanguardsArticle';
import ProjectSlayersArticle from './articles/roblox/ProjectSlayersArticle';
import SlapBattlesArticle from './articles/roblox/SlapBattlesArticle';    
import NotFound from './components/NotFound';
import './App.css';

const Footer = () => (
  <footer className="footer">
    <p>&copy; Nyx Play 2024. All rights reserved.</p>
    <p>
      Follow us on{' '}
      <a href="https://x.com/NyxPlay__?t=Y7ZF7STiukD56zupPrb94A&s=09" target="_blank" rel="noopener noreferrer">Twitter</a>,{' '}
      <a href="https://www.facebook.com/profile.php?id=61565341744188" target="_blank" rel="noopener noreferrer">Facebook</a>, and{' '}
      <a href="https://www.instagram.com/nyx_play?igsh=MTFhNmIyZjhyOXlvcg==" target="_blank" rel="noopener noreferrer">Instagram</a>.
    </p>
    <p>
      <a href="/privacy-policy">Privacy Policy</a> | <a href="/terms-of-service">Terms of Service</a>
    </p>
  </footer>
);

function App() {
  const [isGamePlaying, setIsGamePlaying] = useState(false);

  const articleRoutes = [
    { path: "/articles/blox-fruits-article", component: <BloxFruitsArticle /> },
    { path: "/articles/murder-mystery-2", component: <MurderMystery2Article /> },
    { path: "/articles/adopt-me", component: <AdoptMeArticle /> },
    { path: "/articles/anime-fighters-simulator", component: <AnimeFightersSimulatorArticle /> },
    { path: "/articles/brookhaven", component: <BrookhavenArticle /> },
    { path: "/articles/pet-simulator-x", component: <PetSimulatorXArticle /> },
    { path: "/articles/tower-defense-simulator", component: <TowerDefenseSimulatorArticle /> },
    { path: "/articles/anime-vanguards", component: <AnimeVanguardsArticle /> },
    { path: "/articles/project-slayers", component: <ProjectSlayersArticle /> },
    { path: "/articles/slap-battles", component: <SlapBattlesArticle /> },
  ];

  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<GameList />} />
          <Route path="/game/:id" element={<GamePlayer onPlayStateChange={setIsGamePlaying} />} />
          <Route path="/about" element={<About />} /> 
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          {articleRoutes.map(({ path, component }, index) => (
            <Route key={index} path={path} element={component} />
          ))}
          <Route path="*" element={<NotFound />} />
        </Routes>
        {!isGamePlaying && <Footer />}
      </div>
    </Router>
  );
}

export default App;
