import React from 'react';

const PrivacyPolicy = () => {
  const containerStyle = {
    backgroundColor: '#1F1F1F',
    color: '#FFFFFF',
    padding: '30px',
    borderRadius: '12px',
    maxWidth: '800px',
    margin: 'auto',
    lineHeight: '1.6',
    textAlign: 'left',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',
  };

  const headingStyle = {
    borderBottom: '3px solid #FFFFFF',
    paddingBottom: '8px',
    marginBottom: '24px',
    fontSize: '2em',
  };

  const subHeadingStyle = {
    marginBottom: '12px',
    fontSize: '1.5em',
  };

  const listStyle = {
    marginBottom: '12px',
    paddingLeft: '20px',
  };

  const linkStyle = {
    color: '#1E90FF',
    textDecoration: 'none',
  };

  const footerStyle = {
    marginTop: '20px',
    fontSize: '0.9em',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Privacy Policy</h1>
      <p>Your privacy is important to us. This privacy statement explains the personal data Nyx Play processes, how Nyx Play processes it, and for what purposes.</p>
      
      <h2 style={subHeadingStyle}>1. Information We Collect</h2>
      <p>We collect information to provide better services to all our users. The types of information we collect include:</p>
      <ul style={listStyle}>
        <li><strong>Personal Information:</strong> Such as name, email address, etc.</li>
        <li><strong>Usage Data:</strong> Information on how our service is used.</li>
        <li><strong>Third-Party Information:</strong> We use HTML games from <a href="https://www.htmlgames.com/" style={linkStyle}>HTML Games</a>, which may collect additional data in accordance with their privacy policies.</li>
      </ul>

      <h2 style={subHeadingStyle}>2. How We Use Your Information</h2>
      <p>We use the collected information to:</p>
      <ul style={listStyle}>
        <li>Provide and maintain our services.</li>
        <li>Notify you about changes to our services.</li>
        <li>Monitor the usage of our services.</li>
        <li>Provide customer support.</li>
        <li>Improve our services and ensure a better user experience.</li>
      </ul>

      <h2 style={subHeadingStyle}>3. Security of Your Data</h2>
      <p>The security of your data is important to us. However, please remember that no method of transmission over the Internet or electronic storage is 100% secure. We strive to use commercially acceptable means to protect your data, but we cannot guarantee its absolute security.</p>

      <h2 style={subHeadingStyle}>4. Data Retention</h2>
      <p>We retain your data only as long as necessary to fulfill the purposes outlined in this Privacy Policy, or as required by law. We use criteria such as the nature of the data, the purpose for which it was collected, and applicable legal requirements to determine retention periods.</p>

      <h2 style={subHeadingStyle}>5. User Rights</h2>
      <p>You have the right to access, correct, or delete your personal information. You can also object to or restrict our processing of your data. To exercise these rights, please contact us using the information provided below.</p>

      <h2 style={subHeadingStyle}>6. Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>

      <div style={footerStyle}>
        <p>If you have any questions about this Privacy Policy or your data, please contact us at <a href="mailto:support@nyxplay.com" style={linkStyle}>support@nyxplay.com</a>. For data protection inquiries, you can also reach out to our Data Protection Officer at <a href="mailto:dpo@nyxplay.com" style={linkStyle}>dpo@nyxplay.com</a>.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
